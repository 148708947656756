import DataTable from "react-data-table-component";
import DashboardLayouts from "../../../components/DashboardLayouts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jobAction } from "../../../lib/slice/jobSlice";
import { getJobPostID } from "../../../api/getJobPost";

const AnalyticsJobList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const { manageJobs = [] } = useSelector((state) => state.jobs);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button className="btn btn-sm btn-primary me-2 py-0 px-1">
            <span
              className="material-symbols-outlined"
              onClick={() => handleShow(row)}
              style={{ fontSize: "16px", marginTop: "5px" }}
            >
              visibility
            </span>
          </button>
        </div>
      ),
    },
  ];

  const handleShow = (row) => {
    const jobId = row?.job_id;
    navigate("/analytics", { state: { ...row, job_id: jobId } });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getJobPostID(newuser.email);
      setLoading(false);
      const data = response.data.jobs;
      if (data) {
        dispatch(jobAction.setManageJobs(data));
      }
    } catch (error) {
      console.log("!!! analytics card", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DashboardLayouts>
        <DataTable
          columns={columns}
          data={manageJobs}
          pagination={true}
          progressPending={loading}
          responsive={true}
          highlightOnHover={true}
          striped={true}
          progressComponent={
            <div className="loader">
              <div className="ring"></div>
              <span>loading...</span>
            </div>
          }
        />
      </DashboardLayouts>
    </>
  );
};
export default AnalyticsJobList;
