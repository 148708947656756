import { useEffect, useState } from "react";
import DashboardLayouts from "../../components/DashboardLayouts";
import dashboard1 from "../../assets/images/dashboard1.png";
import Add from "../../assets/images/Add.svg";
import DataTable from "react-data-table-component";
import { baseUrl } from "../../Request";
import CounterDashboard from "../../components/counerDashboard";
import { getMeetingDetails } from "../../api/scheduleAPI";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Dashboard = () => {
  // const dispatch = useDispatch();
  const [dataCandidates, setCandidates] = useState([]);
  const [newJob, setNewJobs] = useState([]);
  const [Meetings, setMeetings] = useState([]);

  const [counts, setCounts] = useState({
    pending: 0,
    shortlisted: 0,
    notInterested: 0,
    invitedtointerview: 0,
  });
  const [openTab, setOpenTab] = useState("Jobs");
  const getJobId = localStorage.getItem("jobID");
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const [loading, setLoading] = useState(false);

  const statusCodes = {
    0: "Pending",
    1: "Shortlisted",
    2: "Not Interested",
    3: "Invited to Interview",
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
  ];

  // Candidates

  const fetchDataNew = async () => {
    if (getJobId !== null) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${baseUrl}/all_candidates?jobid=${getJobId}`,
          requestOptions
        );
        const result = await response.json();
        setCandidates(Object.values(result));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const fetchMeetings = async (
    startDate = "2024-11-6",
    endDate = "2024-11-6",
    email = newuser.email
  ) => {
    setLoading(true);
    try {
      const response = await getMeetingDetails(startDate, endDate, email);
      setLoading(false);
      setMeetings(response.data);
    } catch (error) {
      console.log("!!! fetchMeetings", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMeetings(1);
  }, []);

  useEffect(() => {
    fetchDataNew(2);
  }, []);

  const getBadge = (status) => {
    switch (status) {
      case "Invited to Interview":
        return "success";
      case "Shortlisted":
        return "secondary";
      case "Not Interested":
        return "danger";
      default:
        return "primary";
    }
  };

  const columnsNew = [
    {
      name: "Name",
      selector: (row) => row.Name,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "250px",
    },
    {
      name: "Job Applied For",
      selector: (row) => row.Job_Applied_For,
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className={`bg-${getBadge(row.Status)} text-white border-0 rounded-1`}
        >
          {row.Status}
        </button>
      ),
      width: "200px",
    },
  ];

  const tabs = [
    {
      name: "Jobs",
      link: "#",
      content: (
        <DataTable
          columns={columns}
          data={newJob}
          pagination={true}
          progressPending={loading}
          responsive={true}
          highlightOnHover={true}
          striped={true}
        />
      ),
    },
    {
      name: "Candidates",
      link: "#",
      content: (
        <div>
          <div>
            <DataTable
              columns={columnsNew}
              data={dataCandidates}
              pagination={true}
              progressPending={loading}
              responsive={true}
              highlightOnHover={true}
              striped={true}
            />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (getJobId !== null) {
        const newCounts = {};

        for (const [status, label] of Object.entries(statusCodes)) {
          try {
            const response = await fetch(
              `${baseUrl}/get_count_list?jobid=${getJobId}&status=${status}`,
              { method: "GET", redirect: "follow" }
            );

            const result = await response.json();
            newCounts[label.toLowerCase().replace(/\s+/g, "")] = result.count;
          } catch (error) {
            console.error(`Error fetching ${label} count: `, error);
          }
        }
        setCounts(newCounts);
      }
    };

    fetchData();
  }, [getJobId]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/get_all_jobs?email=${newuser.email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setNewJobs(result.jobs || []))
      .catch((error) => console.error(error));
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const id = urlParams.get("id");

    const userData = {
      message: message,
      id: id,
    };
    if (userData.id !== null) {
      const formdata = new FormData();
      formdata.append("id", userData.id);
      formdata.append("email", newuser.email);
      formdata.append("platform", "google_meet");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseUrl}/update_or_add_email`, requestOptions)
        .then((response) => response.json())
        .then((result) => toast.success(result.message))
        .catch((error) => console.error(error));
    }
  }, [navigate]);

  return (
    <>
      <DashboardLayouts>
        {loading ? (
          <div className="loader">
            <div className="ring"></div>
            <span>loading...</span>
          </div>
        ) : (
          <div className="dashboard container-fluid">
            <div className="row">
              <div className="col-sm-6 col-md-8 col-lg-8">
                <div className="container-fluid">
                  <div className="mt-3 row p-2 row-gap-5">
                    <CounterDashboard
                      count_No={newJob.length}
                      counter_Name="Job Posted"
                      counter_Image={dashboard1}
                    />
                    <CounterDashboard
                      count_No={counts.shortlisted || 0}
                      counter_Name="Shortlisted Candidates"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={0}
                      counter_Name="Reports scheduled"
                      counter_Image={dashboard1}
                    />
                    <CounterDashboard
                      count_No={counts.invitedtointerview || 0}
                      counter_Name="Interview Scheduled"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={counts.pending || 0}
                      counter_Name="Job Interview Pending"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={0}
                      counter_Name="Job Interview confirmed"
                      counter_Image={dashboard1}
                    />
                  </div>
                  <div>
                    <div>
                      <ul className="nav nav-underline">
                        {tabs.map((tab, index) => (
                          <li key={index} className="nav-item">
                            <a
                              href={tab.link}
                              onClick={() => setOpenTab(tab.name)}
                              className={`nav-link ${
                                openTab === tab.name ? "active" : ""
                              }`}
                            >
                              {tab.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            tab.name === openTab ? "d-block" : "d-none"
                          }
                        >
                          {tab.content}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 shadow rounded-4 bg-white border">
                <div>
                  <p className="mt-3">
                    Upcomings Meetings
                    <span>
                      <img
                        src={Add}
                        alt="Add"
                        className="img-fluid ms-1"
                        style={{ width: "23px", height: "auto" }}
                      />
                    </span>
                  </p>

                  <div className="py-2">
                    <div className="mb-2">
                      <span>This Week</span>
                    </div>
                    <div>
                      {Meetings.map((interview, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: index > 0 ? "0.05rem" : "0",
                            backgroundColor: "#5c8ed3",
                            borderRadius: "5px",
                            borderRight: `6px solid ${interview.borderColor}`,
                          }}
                        >
                          <div className="d-flex justify-content-between gap-3 text-white">
                            <span
                              className="fw-bold p-2"
                              style={{
                                borderRight: `3px solid ${`#fff`}`,
                                color: interview.borderColor,
                                fontSize: "12px",
                              }}
                            >
                              {interview.start_time}
                            </span>
                            <span
                              style={{
                                fontSize: "10px",
                              }}
                              className="p-1"
                            >
                              <b style={{ color: `${`#000`}` }}>
                                {interview.candidate_name}
                              </b>
                              <span className="mx-1">:</span>
                              <Link
                                to={interview.join_link}
                                target="_"
                                className="text-white"
                              >
                                {interview.join_link}
                              </Link>
                              <b style={{ color: `${`#000`}` }}>
                                <span className="mx-1">|</span>
                                {interview.start_time} - {interview.end_time}
                              </b>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayouts>
    </>
  );
};

export default Dashboard;
