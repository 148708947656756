import { createSlice } from "@reduxjs/toolkit";
import { scheduleInterviewThunk } from "../thunk/scheduleThunk";

const initialState = {
  loading: false,
  schedule: [],
  error: "",
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(scheduleInterviewThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.schedule = {};
    });
    builder.addCase(scheduleInterviewThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.schedule = action.payload;
      state.error = "";
      console.log("schedule Interview successfully: ", action.payload);
    });
    builder.addCase(scheduleInterviewThunk.rejected, (state, action) => {
      state.loading = false;
      state.schedule = {};
      state.error = "Error occurred";
      console.log("schedule Interview error: ", action.payload);
    });
  },
});

export const scheduleAction = scheduleSlice.actions;
