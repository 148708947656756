import Slider from "react-slick";
import CardImage from "../../../assets/images/card-silder.png";
import { ScheduleData } from "../../../utils/data";
import { Element } from "react-scroll";

const SiderCard = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Element name="Features" className="features-sider-card">
        <div className="sider-card mb-5">
          <div className="container-fuild mx-3">
            <Slider {...settings} className="banner-slider">
              {ScheduleData.map((item, index) => {
                return (
                  <div className="px-2" key={index}>
                    <div className="sidercard card rounded-4">
                      <img
                        src={item.img}
                        className="sider-card-img"
                        alt={CardImage}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{item.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Element>
    </>
  );
};
export default SiderCard;
