import DashboardLayouts from "../../../components/DashboardLayouts";

const SubscriptionsPlans = () => {
  return (
    <div>
      <DashboardLayouts>
        <div className="subscriptions-plans">
          <div className="text-center line-plans mb-4">
            <h3 className="fw-semibold">
              SUBSCRIPTION PLANS PACKAGES PRICING TABLE
            </h3>
          </div>
          <div className="subscriptions-card">
            <div className="w-75 mx-auto">
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <div className="subscriptions-free card shadow-lg border-0 rounded-4">
                    <div className="card-body">
                      <h4>Free</h4>
                      <div className="text-center">
                        <h2 className="fw-bolder py-3">$0</h2>
                        <ul class="navbar-nav">
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="subscriptions-paid card shadow-lg border-0 rounded-4">
                    <div className="card-body">
                      <h4>Paid</h4>
                      <div className="text-center">
                        <h2 className="fw-bolder py-3">$50</h2>
                        <ul class="navbar-nav">
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                          <li class="nav-item d-flex justify-content-center gap-2 mb-3">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#5d5fef" }}
                            >
                              check_circle
                            </span>
                            Lorem ipsum dolor sit
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayouts>
    </div>
  );
};
export default SubscriptionsPlans;
