import File from "../assets/images/AI-File.svg";
import Report from "../assets/images/Graph-Report.svg";
import Week from "../assets/images/Minus-Week.svg";
import UploadFile from "../assets/images/Upload-File.svg";
import image1 from "../assets/images/features1.svg";
import image2 from "../assets/images/features2.svg";
import image3 from "../assets/images/features3.svg";
import image4 from "../assets/images/features4.svg";
import image5 from "../assets/images/features5.svg";
import image6 from "../assets/images/features6.svg";
import imageLogo from "../assets/images/testimonials_user.png";
import testimonials1 from "../assets/images/testimonials-user.png";
import testimonials2 from "../assets/images/Ellipse1.png";
import testimonials3 from "../assets/images/Ellipse2.png";

export const accordionData = [
  {
    title: "How does Employo’s AI-powered ATS scoring work?",
    content:
      "Employo’s AI-powered ATS scoring uses advanced machine learning algorithms to analyze resumes and rank candidates based on your specific hiring criteria. The system evaluates various factors, including skills, experience, and qualifications, to generate a precise score that helps you identify the best fit for your job openings.",
  },
  {
    title: "Can I customize the scoring criteria in Employo AI?",
    content:
      "Yes, you can fully customize the scoring criteria in Employo AI to align with your company’s specific needs. You can prioritize certain skills, experiences, and qualifications to ensure that the candidates most relevant to your requirements are ranked higher.",
  },
  {
    title: "How does Employo AI handle interview scheduling?",
    content:
      "Employo AI simplifies the interview scheduling process by integrating with your calendar system. You can schedule interviews directly through the platform, send automated invitations, and receive reminders. The platform also allows you to coordinate with multiple stakeholders, ensuring a seamless interview process.",
  },
  {
    title: "Is Employo AI compatible with other HR tools and platforms?",
    content:
      "Yes, Employo AI is designed to integrate with various HR tools and platforms. Whether you use an existing Applicant Tracking System (ATS), HRIS, or calendar software, Employo AI can be easily connected to streamline your recruitment workflow.",
  },
  {
    title: "How does Employo AI ensure the security of our data?",
    content:
      "Data security is a top priority for us at Employo AI. We employ industry-leading security protocols, including encryption and secure servers, to protect your data. Regular audits and updates are conducted to maintain the highest level of security.",
  },
  {
    title: "What types of reports can Employo AI generate?",
    content:
      "Employo AI can generate a variety of reports, including detailed candidate profiles, scoring summaries, performance predictions, and comparison charts. These reports are designed to give you a comprehensive view of each candidate, making it easier to make informed hiring decisions.",
  },
  {
    title: "Does Employo AI support diversity hiring initiatives?",
    content:
      "Yes, Employo AI includes features to support diversity hiring initiatives. Our platform allows you to track diversity metrics and ensure that your hiring process is inclusive and unbiased. You can customize filters and criteria to promote diversity in your candidate selection.",
  },
  {
    title: "How does Employo’s candidate matching work?",
    content:
      "Employo’s candidate matching feature uses AI algorithms to match candidates’ resumes with the job descriptions you provide. It considers factors like skills, experience, and qualifications to recommend the best candidates for the job, ensuring a high match rate.",
  },
  {
    title: "Can Employo AI handle large volumes of resumes?",
    content:
      "Absolutely! Employo AI is built to handle large volumes of resumes efficiently. Whether you’re managing hundreds or thousands of applications, our platform can quickly parse, analyze, and rank candidates, saving you significant time and effort.",
  },
  {
    title: "How do I get started with Employo AI?",
    content:
      "Getting started with Employo AI is simple. You can sign up for a free trial on our website, or contact our sales team to request a demo. Once you’re onboard, our team will assist you in setting up the platform according to your needs and provide ongoing support to ensure your success.",
  },
];
export const ScheduleData = [
  {
    img: UploadFile,
    title: "Upload Resumes",
    content: "Quickly upload and organize resumes from various sources.",
  },
  {
    img: File,
    title: "AI-Powered Scoring",
    content:
      "Let Employo’s AI analyze and rank candidates based on your specific needs.",
  },
  {
    img: Report,
    title: "Detailed Reports",
    content:
      "Receive in-depth analysis and scoring to make informed decisions.",
  },
  {
    img: Week,
    title: "Easy Scheduling",
    content: "Schedule interviews effortlessly, directly from the platform.",
  },
];
export const featuresCardData = [
  {
    img: image6,
    title: "Resume Parsing",
    content: "Extract critical data from resumes in seconds.",
  },
  {
    img: image5,
    title: "Smart Matching",
    content: "AI algorithms match candidates with your job requirements.",
  },
  {
    img: image2,
    title: "Customizable Filters",
    content: "Filter candidates based on skills, experience, and more.",
  },
  {
    img: image4,
    title: "Collaborative Hiring",
    content: "Share candidate profiles and feedback with your team",
  },
  {
    img: image3,
    title: "Diversity Tracking",
    content: "Ensure a diverse pool of candidates.",
  },
  {
    img: image1,
    title: "Secure Data Management",
    content: "Protect your data with industry-leading security.",
  },
];

export const clientsData = [
  {
    img: testimonials1,
    ClientsName: "John Smith",
    position: "HR Manager at AZ Tech Solutions",
    reviews:
      "We struggled to find the right talent globally, but with their automated candidate ranking, we quickly identified top-notch candidates who perfectly fit our requirements.",
  },
  {
    img: testimonials2,
    ClientsName: "Sarah Johnson",
    position: "CTO of Studybot Innovations Lab",
    reviews:
      "As a fast-growing startup, we needed an efficient way to find skilled professionals from various regions. This AI tool exceeded our expectations.",
  },
  {
    img: testimonials3,
    ClientsName: "Michael Chen",
    position: "HR Director at Acme Enterprises",
    reviews:
      "Employo AI’s emphasis on diversity and inclusion impressed me, helping us create a more inclusive workforce.",
  },
];
