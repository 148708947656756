export const ManageDetails = ({ exampleModal, ManageData }) => {
  const ManageDataNew = ManageData || [];
  return (
    <div
      className="modal fade"
      id={exampleModal}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Manage Jobs
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {ManageDataNew ? (
            <div className="modal-body">
              <div className="container">
                <div className="row mb-3">
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">{ManageData.job_title}</h6>
                    <p>Job Title</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Experience Level</h6>
                    <p>{ManageData.experience_level}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Country</h6>
                    <p>{ManageData.country}</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">State</h6>
                    <p>{ManageData.state}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Working Type</h6>
                    <p>{ManageData.working_type}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Schedule</h6>
                    <p>{ManageData.schedule}</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Salary Range</h6>
                    <p>{`${ManageData.salary_max}-${ManageData.salary_min}`}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Number of Positions</h6>
                    <p>{ManageData.no_of_positions}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Timeline</h6>
                    <p>{ManageData.timeline}</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Email</h6>
                    <p>{ManageData.email}</p>
                  </div>
                  <div className="cols-m-6 col-md-4">
                    <h6 className="fw-bold">Resumes Submitted</h6>
                    <p>{ManageData.resume_count}</p>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <h6 className="fw-bold">Benefits</h6>
                    <ul>
                      {ManageData.benefits
                        ? ManageData.benefits.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })
                        : ""}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <h6 className="fw-bold">Compensation</h6>
                    <ul>
                      {ManageData.compensation
                        ? ManageData.compensation.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })
                        : ""}
                    </ul>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h6 className="fw-bold">Job Description</h6>
                    <p>{ManageData.job_description}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="p-2">No data found.</p>
          )}
        </div>
      </div>
    </div>
  );
};
