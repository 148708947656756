import React, { useState } from "react";
import employoLogo from "../../../assets/images/Employo-sign-login.png";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ForgetPasswordThunk } from "../../../lib/thunk/user";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is not valid";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        await dispatch(ForgetPasswordThunk(formData));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="sign-up">
      <div className="sign-up-section d-block d-md-flex position-relative">
        <Link
          to="/login"
          className="position-absolute px-3 py-3 text-decoration-none d-flex gap-0"
        >
          <span className="material-symbols-outlined text-white">
            arrow_back_ios
          </span>
          <p className="underline text-lg cursor-pointer mb-5 text-white">
            Back
          </p>
        </Link>
        <div className="left w-100 w-md-50 p-4">
          <div>
            <div className="text-center">
              <Link className="navbar-brand" to="/">
                <img
                  src={employoLogo}
                  alt={employoLogo}
                  className="img-fluid h-auto"
                  style={{ width: "180px", marginLeft: "-18px" }}
                />
              </Link>
            </div>
            <h2 className="mb-3">AI-Powered Applicant Tracking System (ATS)</h2>
            <p>
              Employo AI helps to streamline hiring and effortlessly find,
              hire, and manage top talent. The fastest, smartest, most secure,
              and feature-rich end-to-end recruitment software for hiring!
            </p>
            <ul>
              <li>Employee Onboarding Platform</li>
              <li>AI-Driven Recommendations</li>
              <li>AI Powered Recruiter</li>
              <li>Advanced Analytics & Metrics</li>
            </ul>
          </div>
        </div>
        <div className="right w-100 w-md-50 p-4">
          <div className="p-4">
            <h2 className="mb-4">We will send you a link to reset password.</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="row">
                  <div className="col-sm-6 col-md-12">
                    <div className="mb-4">
                      <input
                        type="email"
                        className="form-control shadow-none p-2"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email *"
                      />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>
                    {loading ? (
                      <button
                        type="submit"
                        className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                        style={{ background: "#f4812d", border: 0 }}
                        disabled
                      >
                        Forgot Password
                        <div
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                        style={{ background: "#f4812d", border: 0 }}
                      >
                        Forgot Password
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="mt-3 mb-3 text-center" style={{ fontSize: "14px" }}>
              Already have an account?
              <Link to="/login" className="ms-2" style={{ color: "#f4812d" }}>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
