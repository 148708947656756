import { API } from "../Request";

export const scheduleInterviewAPI = async (data) =>
  await API.post("/create_event", data, {
     
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getMeetingDetails = (start_date, end_date, email) => {
  return API.request({
    url: `/get_meeting_details?start_date=${start_date}&end_date=${end_date}&email=${email}`,
    method: "GET",
  });
};
