import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AnalysisNotes,
  deleteJobsID,
  jobApplyDataAPI,
  jobFetchDataAPI,
  jobPostAPI,
  updateJobPostAPI,
} from "../../api/jobPostAPI";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export const jobPostThunk = createAsyncThunk(
  "jobPostThunk",
  async (data, thunkAPI) => {
    const jobID = uuidv4();
    const jobData = new FormData();
    jobData.append("jobID", jobID);
    jobData.append("jobTitle", data.title);
    jobData.append("working_type", data.workingType);
    jobData.append("country", data.country);
    jobData.append("state", data.state);
    jobData.append("experience_level", data.experience);
    jobData.append("schedule", data.schedule);
    jobData.append("timeline", data.timeline);
    jobData.append("salary_min", data.minAmount);
    jobData.append("salary_max", data.maxAmount);
    jobData.append("compensation", JSON.stringify(data.compensation));
    jobData.append("benefits", JSON.stringify(data.benefits));
    jobData.append("jobDescription", data.description);
    jobData.append("no_of_positions", data.positions);
    jobData.append("email", data.email);
    const files = Array.from(data.files);
    files.forEach((file) => jobData.append("resumes", file));

    try {
      const response = await jobPostAPI(jobData);
      if (
        response.data.message ==
        "Job posting added to the database successfully!"
      ) {
        localStorage.setItem("jobID", jobID);
        toast.success(response.data.message);
        return response.data;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const AnalysisNotesThunk = createAsyncThunk(
  "AnalysisNotesThunk",
  async (data, thunkAPI) => {
    let notesData = new FormData();
    notesData.append("email", data.email);
    notesData.append("jobid", data.jobid);
    notesData.append("notes", data.notes);

    try {
      const response = await AnalysisNotes(notesData);
      if (
        response.data.message ==
        "Note added successfully to all matching entries"
      ) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const AnalysisFetchDataThunk = createAsyncThunk(
  "AnalysisFetchDataThunk",
  async (data, thunkAPI) => {
    let fetchData = new FormData();
    fetchData.append("job_id", data.job_id);
    fetchData.append(data.type, "true");

    try {
      const response = await jobFetchDataAPI(fetchData);
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const updateJobPostAPIThunk = createAsyncThunk(
  "updateJobPostAPIThunk",
  async (data, thunkAPI) => {
    let jobData = new FormData();
    jobData.append("jobTitle", data.title);
    jobData.append("working_type", data.workingType);
    jobData.append("country", data.country);
    jobData.append("state", data.state);
    jobData.append("experience_level", data.experience);
    jobData.append("schedule", data.schedule);
    jobData.append("timeline", data.timeline);
    jobData.append("jobID", data.jobID);
    jobData.append("salary_min", data.minAmount);
    jobData.append("salary_max", data.maxAmount);
    jobData.append("compensation", JSON.stringify(data.compensation));
    jobData.append("benefits", JSON.stringify(data.benefits));
    jobData.append("jobDescription", data.description);
    jobData.append("no_of_positions", data.positions);
    jobData.append("email", data.email);
    const files = Array.from(data.files);
    files.forEach((file) => jobData.append("resumes", file));
    try {
      const response = await updateJobPostAPI(jobData);
      console.log(response, "updateJobPostAPIThunk");
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const DeleteJobsThunk = createAsyncThunk(
  "DeleteJobsThunk",
  async (dataID, thunkAPI) => {
    try {
      const response = await deleteJobsID(dataID);
      if (
        response.data.message == `Job with ID ${dataID} deleted successfully.`
      ) {
        toast.success("Job deleted successfully!");
      } else {
        toast.error("Job ID not found");
      }
      console.log(response.data, "response.data");
      const updatedResponse = Object.assign(response.data, { id: dataID });
      return updatedResponse;
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const JobAppliedThunk = createAsyncThunk(
  "JobAppliedThunk",
  async ({ selectedJobTitle, profileData, status, jobid }, thunkAPI) => {
    const formdata = new FormData();
    formdata.append("email", profileData?.Email);
    formdata.append("Candidate_Id", profileData?.Candidate_Id);
    formdata.append("jobid", jobid);
    formdata.append("status", Number(status));
    formdata.append("selectedJob", selectedJobTitle);

    try {
      const response = await jobApplyDataAPI(formdata);
      if (
        response.data.message ===
        "Status and Job Applied For updated successfully"
      ) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.message || "An error occurred");
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const JobAppliedThunkUpdate = createAsyncThunk(
  "JobAppliedThunkUpdate",
  async ({ selectedJob, status, jobid, row }, thunkAPI) => {
    const formdata = new FormData();
    formdata.append("email", row?.Email);
    formdata.append("Candidate_Id", row.Candidate_Id);
    formdata.append("jobid", jobid);
    formdata.append("status", Number(status));
    formdata.append("selectedJob", selectedJob);

    try {
      const response = await jobApplyDataAPI(formdata);
      if (
        response.data.message ===
        "Status and Job Applied For updated successfully"
      ) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.message || "An error occurred");
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);
