// const NewPassword = () => {
//   return <>new-password</>;
// };

// export default NewPassword;

import React, { useEffect, useState } from "react";
import employoLogo from "../../../assets/images/employo-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Request";

const NewPassword = () => {
  //   const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.newPassword)
      tempErrors.newPassword = "New Passowrd is required";
    if (!formData.confirmNewPassword)
      tempErrors.confirmNewPassword = "Confirm New Passowrd is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    setToken(token);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("password", formData.newPassword);
      formdata.append("re_password", formData.confirmNewPassword);

      const requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch(`${baseUrl}/reset_password/${token}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.message == "Your password has been updated.") {
            navigate("/login");
            toast.success(result.message);
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false); // Stop loading
        });
    }
  };

  return (
    <div className="sign-up">
      <div className="sign-up-section d-block d-md-flex position-relative">
        <div className="left w-100 w-md-50 p-4">
          <div>
            <div className="mb-4 text-center">
              <Link className="navbar-brand" to="/">
                <img
                  src={employoLogo}
                  alt={employoLogo}
                  className="img-fluid h-auto"
                  style={{ width: "180px", marginLeft: "-18px" }}
                />
              </Link>
            </div>
            <h2 className="mb-3">AI-Powered Applicant Tracking System (ATS)</h2>
            <p>
              Employo AI helps to streamline hiring and effortlessly find,
              hire, and manage top talent. The fastest, smartest, most secure,
              and feature-rich end-to-end recruitment software for hiring!
            </p>
            <ul>
              <li>Employee Onboarding Platform</li>
              <li>AI-Driven Recommendations</li>
              <li>AI Powered Recruiter</li>
              <li>Advanced Analytics & Metrics</li>
            </ul>
          </div>
        </div>
        <div className="right w-100 w-md-50 p-4">
          <div className="p-4">
            <h2 className="mb-4">Reset Password</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="row">
                  <div className="col-sm-6 col-md-12">
                    <div className="mb-4">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control shadow-none p-2"
                          placeholder="New passowrd *"
                          name="newPassword"
                          value={formData.newPassword}
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="input-group-text"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <span className="material-symbols-outlined">
                              visibility
                            </span>
                          ) : (
                            <span className="material-symbols-outlined">
                              visibility_off
                            </span>
                          )}
                        </button>
                      </div>
                      {errors.newPassword && (
                        <div className="error-message">
                          {errors.newPassword}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control shadow-none p-2"
                          placeholder="Confirm New Password *"
                          name="confirmNewPassword"
                          value={formData.confirmNewPassword}
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="input-group-text"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <span className="material-symbols-outlined">
                              visibility
                            </span>
                          ) : (
                            <span className="material-symbols-outlined">
                              visibility_off
                            </span>
                          )}
                        </button>
                      </div>
                      {errors.confirmNewPassword && (
                        <div className="error-message">
                          {errors.confirmNewPassword}
                        </div>
                      )}
                    </div>
                    {loading ? (
                      <button
                        type="submit"
                        className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                        style={{ background: "#f4812d", border: 0 }}
                        disabled
                      >
                        Reset Password
                        <div
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                        style={{ background: "#f4812d", border: 0 }}
                      >
                        Reset Password
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
