import React, { useEffect, useState } from "react";
import employoLogo from "../../../assets/images/Employo-sign-login.png";
import { useDispatch, useSelector } from "react-redux";
import { signUpThunk } from "../../../lib/thunk/user";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Request";

const SignUp = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [step, setstep] = useState(1);
  const [otpData, setOTPData] = useState("");
  const { user, message } = useSelector((state) => state.user);
  const navigation = useNavigate();

  useEffect(() => {
    if (message === "Account created") {
      navigation("/dashboard");
    }
  }, [message]);
  const nextStep = () => {
    setstep(step + 1);
  };
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    professional_email: "",
    company: "",
    recruiters_count: "",
    password: "",
    re_password: "",
    terms: false,
    terms_new: false,
    otp: "",
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "phone") {
      const phoneValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({
        ...formData,
        [name]: phoneValue,
      });
    } else if (name === "terms" || name === "terms_new") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.phone || formData.phone.length < 10)
      tempErrors.phone = "Phone number must be 10 digits";
    if (
      !formData.professional_email ||
      !/\S+@\S+\.\S+/.test(formData.professional_email)
    )
      tempErrors.professional_email = "Valid email is required";
    if (!formData.company) tempErrors.company = "Company is required";
    if (!formData.recruiters_count || formData.recruiters_count <= 0)
      tempErrors.recruiters_count =
        "Recruiters count must be a positive number";
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!formData.password) {
      tempErrors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      tempErrors.password =
        "Password must be at least 8 characters, include an uppercase, a lowercase, a number, and a special character.";
    }
    if (!formData.terms) {
      tempErrors.terms = "You must agree to the terms and conditions."; // Set error message
    }
    if (!formData.terms_new) {
      tempErrors.terms_new = "You must agree to the terms and conditions."; // Set error message
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          email: formData.professional_email,
        });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(`${baseUrl}/generate-otp`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.otp && result.error !== "Failed to send email") {
              setOTPData(result);
              toast.success(result.message);
              nextStep();
            } else {
              toast.error(result.error);
            }
          });
      } catch (error) {
        toast.error(error.message);
        console.error("Error during submission:", error);
      }
    }
  };

  const NewhandleSubmit = (e) => {
    e.preventDefault();
    if (otpData.otp == Number(formData.otp)) {
      const handleSignUp = async () => {
        await dispatch(signUpThunk(formData));
        if (message !== "Email already exists") {
          try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
              email: formData.professional_email,
            });

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            const response = await fetch(
              `${baseUrl}/send_welcome_email`,
              requestOptions
            );
            const result = await response.json();
            toast.success(result.message);
          } catch (error) {
            console.error("Error:", error);
          }
        }
      };

      handleSignUp();
    } else {
      console.log("not match");
    }
  };

  switch (step) {
    case 1:
      return (
        <div className="sign-up">
          <div className="sign-up-section d-block d-md-flex position-relative">
            <Link
              to="/"
              className="position-absolute px-3 py-3 text-decoration-none d-flex gap-0"
            >
              <span className="material-symbols-outlined text-white">
                arrow_back_ios
              </span>
              <p className="underline text-lg cursor-pointer mb-5 text-white">
                Back
              </p>
            </Link>
            <div className="left w-100 w-md-50 p-4">
              <div className="text-center">
                <Link className="navbar-brand" to="/">
                  <img
                    src={employoLogo}
                    alt={employoLogo}
                    className="img-fluid h-auto"
                    style={{ width: "180px", marginLeft: "-18px" }}
                  />
                </Link>
              </div>
              <h2 className="mb-3">
                AI-Powered Applicant Tracking System (ATS)
              </h2>
              <p>
                Employo AI helps to streamline hiring and effortlessly find,
                hire, and manage top talent. The fastest, smartest, most secure,
                and feature-rich end-to-end recruitment software for hiring!
              </p>
              <ul>
                <li>Employee Onboarding Platform</li>
                <li>AI-Driven Recommendations</li>
                <li>AI Powered Recruiter</li>
                <li>Advanced Analytics & Metrics</li>
              </ul>
            </div>
            <div className="right w-100 w-md-50 p-4">
              <h2 className="mb-4">
                Sign Up to <span>your account</span>
              </h2>
              <form>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name *"
                      />
                      {errors.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <input
                        type="tel"
                        className="form-control shadow-none"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone *"
                      />
                      {errors.phone && (
                        <div className="error-message">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <input
                        type="email"
                        className="form-control shadow-none"
                        id="professional_email"
                        name="professional_email"
                        value={formData.professional_email}
                        onChange={handleChange}
                        placeholder="Email *"
                      />
                      {errors.professional_email && (
                        <div className="error-message">
                          {errors.professional_email}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        placeholder="Company *"
                      />
                      {errors.company && (
                        <div className="error-message">{errors.company}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 mb-2">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control shadow-none"
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          placeholder="Password *"
                        />
                        <button
                          type="button"
                          className="input-group-text"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <span className="material-symbols-outlined">
                              visibility
                            </span>
                          ) : (
                            <span className="material-symbols-outlined">
                              visibility_off
                            </span>
                          )}
                        </button>
                      </div>
                      {errors.password && (
                        <div className="error-message mb-2">
                          {errors.password}
                        </div>
                      )}
                      {formData.password && (
                        <PasswordStrengthBar
                          password={formData.password}
                          style={{ color: "#fff" }}
                          scoreWords={["weak", "weak", "good", "strong"]}
                        />
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 mb-2">
                      <div className="input-group">
                        <input
                          type={showRePassword ? "text" : "password"}
                          className="form-control shadow-none"
                          id="re_password"
                          name="re_password"
                          value={formData.re_password}
                          onChange={handleChange}
                          placeholder="Confirm Password *"
                        />
                        <button
                          type="button"
                          className="input-group-text"
                          onClick={() => setShowRePassword(!showRePassword)}
                        >
                          {showRePassword ? (
                            <span className="material-symbols-outlined">
                              visibility
                            </span>
                          ) : (
                            <span className="material-symbols-outlined">
                              visibility_off
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <input
                        type="number"
                        className="form-control shadow-none"
                        id="recruiters_count"
                        name="recruiters_count"
                        value={formData.recruiters_count}
                        onChange={handleChange}
                        min="1"
                        placeholder="Recruiters count *"
                      />
                      {errors.recruiters_count && (
                        <div className="error-message">
                          {errors.recruiters_count}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="terms"
                      checked={formData.terms}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="terms"
                      className="form-check-label fw-normal"
                    >
                      I agree to Employo AI’s terms of service
                    </label>
                  </div>
                  {errors.terms && (
                    <div className="error-message">
                      {errors.terms ? errors.terms : ""}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="terms_new"
                      checked={formData.terms_new}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="terms"
                      className="form-check-label fw-normal"
                    >
                      I would like to receive marketing communication from
                      Recruit Employo AI.
                    </label>
                  </div>
                  {errors.terms_new && (
                    <div className="error-message">
                      {errors.terms_new ? errors.terms_new : ""}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                  style={{
                    background: "#f4812d",
                    border: 0,
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                >
                  OTP Verification
                </button>
              </form>
              <div
                className="mt-3 mb-3 text-center"
                style={{ fontSize: "14px" }}
              >
                Already have an account with Employo AI
                <Link to="/login" className="ms-2" style={{ color: "#f4812d" }}>
                  Log in
                </Link>
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <button
                    className="btn border d-flex align-items-center gap-3 text-dark px-3 py-2"
                    onClick={() => {
                      window.location.href = `${baseUrl}/login_google`;
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "24px", height: "24px" }}
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>Sign Up with Google</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
  switch (step) {
    case 2:
      return (
        <div className="sign-up">
          <div className="sign-up-section d-block d-md-flex position-relative">
            <div className="left w-100 w-md-50 p-4">
              <div className="mb-4 text-center">
                <Link className="navbar-brand" to="/">
                  <img
                    src={employoLogo}
                    alt={employoLogo}
                    className="img-fluid h-auto"
                    style={{ width: "180px", marginLeft: "-18px" }}
                  />
                </Link>
              </div>
              <h2 className="mb-3">
                AI-Powered Applicant Tracking System (ATS)
              </h2>
              <p>
                Employo AI helps to streamline hiring and effortlessly find,
                hire, and manage top talent. The fastest, smartest, most secure,
                and feature-rich end-to-end recruitment software for hiring!
              </p>
              <ul>
                <li>Employee Onboarding Platform</li>
                <li>AI-Driven Recommendations</li>
                <li>AI Powered Recruiter</li>
                <li>Advanced Analytics & Metrics</li>
              </ul>
            </div>
            <div className="right w-100 w-md-50 p-4">
              <h2 className="mb-4 text-center">OTP Verification</h2>
              <form>
                <div>
                  <div className="mb-3 otp-container                                                                                                            ">
                    <OtpInput
                      value={formData.otp}
                      onChange={(value) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          otp: value,
                        }))
                      }
                      numInputs={6}
                      inputStyle="otp-input"
                      renderInput={(props) => (
                        <input {...props} type="text" maxLength="1" />
                      )}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                    style={{
                      background: "#f4812d",
                      border: 0,
                      cursor: "pointer",
                    }}
                    onClick={NewhandleSubmit}
                  >
                    Create your account
                  </button>
                </div>
              </form>
              <div
                className="mt-3 mb-3 text-center"
                style={{ fontSize: "14px" }}
              >
                Already have an Account with Recruit CRM with Employo AI.
                <Link to="/login" className="ms-2" style={{ color: "#f4812d" }}>
                  Log in
                </Link>
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <button
                    className="btn border d-flex align-items-center gap-3 text-dark px-3 py-2"
                    onClick={() => {
                      window.location.href = `${baseUrl}/login_google`;
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "24px", height: "24px" }}
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>Sign Up with Google</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
};

export default SignUp;
