import React from "react";
import talently from "../../../assets/images/talently.png";
import { Link } from "react-router-dom";

const RoleWorld = () => {
  return (
    <>
      <div className="summary-section mb-5">
        <div className="summary mb-5">
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <div className="px-2 px-md-5">
                <div className="mb-5">
                  <h2 className="fw-bold">Interview Scheduling Made Easy</h2>
                  <p>Simplify Your Interview Process</p>
                </div>
                <ul className="p-0">
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Integrated calendar for seamless scheduling.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Automated reminders and notifications.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Coordinate with multiple stakeholders effortlessly.
                  </li>
                </ul>
                <div className="banner-btn-section mt-5">
                  <Link
                    className="btn-more p-2 text-decoration-none rounded-1 px-4 fw-semibold me-2"
                    to="/login"
                    style={{ cursor: "pointer" }}
                  >
                    Try now
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <img src={talently} className="img-fluid" alt="talently" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleWorld;
