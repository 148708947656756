import { API } from "../Request";

export const scheduleInterviewAPI = async (data) =>
  await API.post("/create_event", data, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getMeetingDetails = (start_date, end_date, email) => {
  return API.request({
    url: `/get_meeting_details?start_date=2024-10-28&end_date=2024-10-28&email=demo@gmail.com`,
    method: "GET",
  });
};
