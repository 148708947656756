import React from "react";
import talently from "../../../assets/images/talently_1.png";
import { Link } from "react-router-dom";

const Summary = () => {
  return (
    <>
      <div className="summary-section mb-5">
        <div className="summary mb-5">
          <div className="d-block d-md-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <div className="px-2 px-md-5">
                <div className="mb-5">
                  <h2 className="fw-bold">AI-Powered ATS Scoring</h2>
                  <p>Let AI Do the Heavy Lifting</p>
                </div>
                <ul className="p-0">
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Automated resume screening with high accuracy.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Tailored scoring based on your company’s unique criteria.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Instant insights that save time and reduce bias.
                  </li>
                </ul>
                <div className="banner-btn-section mt-5">
                  <Link
                    className="btn-more p-2 text-decoration-none rounded-1 px-4 fw-semibold me-2"
                    to="/login"
                    style={{ cursor: "pointer" }}
                  >
                    Try now
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <img
                src={talently}
                alt="framerusercontent"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
