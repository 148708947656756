import { useEffect, useState } from "react";
import DashboardLayouts from "../../components/DashboardLayouts";
import dashboard1 from "../../assets/images/dashboard1.png";
import DataTable from "react-data-table-component";
import { baseUrl } from "../../Request";
import CounterDashboard from "../../components/counerDashboard";
import { getMeetingDetails } from "../../api/scheduleAPI";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { scheduleInterviewThunk } from "../../lib/thunk/scheduleThunk";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dataCandidates, setCandidates] = useState([]);
  const [newJob, setNewJobs] = useState([]);
  const [Meetings, setMeetings] = useState([]);
  const [MeetingsError, setMeetingsError] = useState("");
  const [counts, setCounts] = useState({});
  const [openTab, setOpenTab] = useState("Jobs");
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const [loading, setLoading] = useState(false);
  const dashboarData = location.state;

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
  ];

  // Candidates

  const fetchDataNew = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/all_candidates?email=${newuser.email}`,
        requestOptions
      );
      const result = await response.json();
      // setCandidates(Object.values(result));
      const scores = Array.from(result?.candidates);
      const candidates = scores.flatMap((item) => {
        return item?.scores?.map((subItem) => {
          return {
            job_id: item?.job_id,
            ...subItem,
          };
        });
      });

      setCandidates(candidates);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const categorizeMeetings = (meetings) => {
    const now = new Date();
    const normalizedToday = new Date(now.setHours(0, 0, 0, 0));

    const yesterday = new Date(normalizedToday);
    yesterday.setDate(normalizedToday.getDate() - 1); // Set to yesterday's date

    const startOfWeek = new Date(normalizedToday);
    startOfWeek.setDate(normalizedToday.getDate() - normalizedToday.getDay()); // Start of the week (Sunday)

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Saturday)

    // Categorize meetings
    return meetings.reduce(
      (categories, meeting) => {
        const meetingDate = new Date(
          new Date(meeting.date).setHours(0, 0, 0, 0)
        );

        // Check for "Today"
        if (meetingDate.getTime() === normalizedToday.getTime()) {
          categories.today.push(meeting);
        }
        // Check for "Yesterday"
        else if (meetingDate.getTime() === yesterday.getTime()) {
          categories.yesterday.push(meeting);
        }
        // Check for "This Week" (Sunday to Saturday)
        else if (meetingDate >= startOfWeek && meetingDate <= endOfWeek) {
          categories.thisWeek.push(meeting);
        }

        return categories;
      },
      {
        today: [],
        yesterday: [],
        thisWeek: [],
      }
    );
  };

  const fetchMeetings = async (
    startDate = formatDate(new Date("1970-01-01")),
    endDate = formatDate(new Date("9999-12-31")),
    email = newuser.email
  ) => {
    setLoading(true);
    try {
      const response = await getMeetingDetails(startDate, endDate, email);
      setLoading(false);
      const categorizedMeetings = categorizeMeetings(response.data);
      setMeetings(categorizedMeetings);
    } catch (error) {
      setMeetingsError(error?.response?.data?.message);
      console.log("!!! fetchMeetings", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMeetings();
  }, []);

  useEffect(() => {
    fetchDataNew();
  }, []);

  const getBadge = (status) => {
    switch (status) {
      case "Invited to Interview":
        return "success";
      case "Shortlisted":
        return "secondary";
      case "Not Interested":
        return "danger";
      default:
        return "primary";
    }
  };

  const columnsNew = [
    {
      name: "Name",
      selector: (row) => row.Name,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "250px",
    },
    {
      name: "Job Applied For",
      selector: (row) => row.Job_Applied_For,
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className={`bg-${getBadge(row.Status)} text-white border-0 rounded-1`}
        >
          {row.Status}
        </button>
      ),
      width: "200px",
    },
  ];

  const tabs = [
    {
      name: "Jobs",
      link: "#",
      content: (
        <DataTable
          columns={columns}
          data={newJob}
          pagination={true}
          progressPending={loading}
          responsive={true}
          highlightOnHover={true}
          striped={true}
        />
      ),
    },
    {
      name: "Candidates",
      link: "#",
      content: (
        <div>
          <div>
            <DataTable
              columns={columnsNew}
              data={dataCandidates}
              pagination={true}
              progressPending={loading}
              responsive={true}
              highlightOnHover={true}
              striped={true}
            />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/dashboard_counts?email=${newuser.email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCounts(result))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/get_all_jobs?email=${newuser.email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setNewJobs(result.jobs || []))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const id = urlParams.get("id");
    const userData = {
      message: message,
      id: id,
    };
    if (userData.id !== null) {
      const formdata = new FormData();
      formdata.append("id", userData.id);
      formdata.append("email", newuser.email);
      formdata.append("platform", "google_meet");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseUrl}/update_or_add_email`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          toast.success(result.message);
        })
        .catch((error) => console.error(error));
    }
  }, [navigate]);

  return (
    <>
      <DashboardLayouts>
        {loading ? (
          <div className="loader">
            <div className="ring"></div>
            <span>loading...</span>
          </div>
        ) : (
          <div className="dashboard container-fluid">
            <div className="row">
              <div className="col-sm-6 col-md-8 col-lg-8">
                <div className="container-fluid">
                  <div className="mt-3 row p-2 row-gap-5">
                    <CounterDashboard
                      count_No={counts.JobPosted}
                      counter_Name="Job Posted"
                      counter_Image={dashboard1}
                    />
                    <CounterDashboard
                      count_No={counts.InterviewScheduled}
                      counter_Name="Shortlisted Candidates"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={counts.ReportsScheduled}
                      counter_Name="Reports scheduled"
                      counter_Image={dashboard1}
                    />
                    <CounterDashboard
                      count_No={counts.InterviewScheduled}
                      counter_Name="Interview Scheduled"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={counts.JobInterviewPending}
                      counter_Name="Job Interview Pending"
                      counter_Image={dashboard1}
                    />

                    <CounterDashboard
                      count_No={counts.JobInterviewConfirmed}
                      counter_Name="Job Interview confirmed"
                      counter_Image={dashboard1}
                    />
                  </div>
                  <div>
                    <div>
                      <ul className="nav nav-underline">
                        {tabs.map((tab, index) => (
                          <li key={index} className="nav-item">
                            <a
                              href={tab.link}
                              onClick={() => setOpenTab(tab.name)}
                              className={`nav-link ${
                                openTab === tab.name ? "active" : ""
                              }`}
                            >
                              {tab.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            tab.name === openTab ? "d-block" : "d-none"
                          }
                        >
                          {tab.content}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 shadow rounded-4 bg-white border">
                <div>
                  <p className="mt-3 d-flex align-items-center gap-1 mb-2">
                    Upcomings Meetings
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "#5d5fef" }}
                    >
                      event_upcoming
                    </span>
                  </p>
                  <div>
                    {MeetingsError && MeetingsError}
                    {Object.keys(Meetings).map((category) => (
                      <div key={category}>
                        <h6
                          className={`text-capitalize fw-semibold m-0 ${
                            Meetings[category].length > 0 ? "mb-2" : ""
                          }`}
                          style={{ fontSize: "14px" }}
                        >
                          {category}
                        </h6>

                        {Meetings[category] && Meetings[category].length > 0 ? (
                          Meetings[category].map((interview, index) => (
                            <div className="metting-dashboard" key={index}>
                              <div
                                class="text-white p-2 mb-2 rounded-lg position-relative speech-bubble-right"
                                style={{
                                  backgroundColor:
                                    category === "today"
                                      ? "#5d5fef"
                                      : category === "yesterday"
                                      ? "#f4812d"
                                      : category === "thisWeek"
                                      ? "#737791"
                                      : "#000",
                                }}
                              >
                                <div>
                                  <div className="m-0 d-flex align-items-center justify-content-between">
                                    <p
                                      style={{
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                      className="mb-0"
                                    >
                                      {interview.candidate_name}
                                    </p>
                                    <p
                                      class="text-white small text-end mb-0"
                                      style={{
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {interview.start_time} -
                                      {interview.end_time}
                                    </p>
                                  </div>
                                  <div>
                                    <Link
                                      to={interview.join_link}
                                      target="_"
                                      style={{
                                        fontSize: "11px",
                                        color: "#fff",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {interview.join_link}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{ color: "#666", fontSize: "12px" }}
                            className="py-2"
                          >
                            No data found
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayouts>
    </>
  );
};

export default Dashboard;
